<template>  
    <BlockUI :blocked="blockComponent">
        <Toast />
        <ConfirmPopup></ConfirmPopup>
        <Sidebar v-model:visible="formVisible" :baseZIndex="1000" :autoZIndex="true" :dismissable="false" :showCloseIcon="false" @hide="closeForm" position="right" :class="'col-12 lg:col-8 md:col-10 sm:col-12 xs:col-12 mt-0'"> 
           
                <div class="mt-0 mb-0">
                    <div class="col-12 lg:col-12 mb-0">
                        <h3 class="mb-0"> {{module_name}}</h3> 
                    </div>
                    <Divider class="mt-0" />
                    <TabView  @tab-click="loadTargetedTab($event)" class="tabview-custom" v-model:activeIndex="activeTab" :scrollable="true">
                         <TabPanel header="Student Information">
                            <template #header>
                                &nbsp; <i class="pi pi-info-circle"></i>
                            </template>
                            <div class="p-panel p-component col-12 lg:col-12 sm:col-12" style="float: left;"> 
                                <div class="p-toggleable-content" role="region" id="pv_id_6_content" aria-labelledby="pv_id_6_header">
                                    <div class="p-panel-content">
                                        <div class="col-12 lg:col-2 sm:col-2 mb-2 profileCardImg" style="float: left;">
                                            <div class="mb-2">
                                            <img  v-if="data.profile_pic" :alt="data.name" :src="studentFiles+'students/'+data.id+'/'+data.profile_pic" style="width:180px; height: 180px;" />
                                            <img  v-if="!data.profile_pic" :alt="data.name" :src="filesUrl+'defaults/default.png'" style="width:180px; height: 180px;" />
                                            </div>
                                            <div>
                                                <span class="p-tag p-component p-tag-success p-tag-rounded mb-2 span-100 font-14 mb-2 span-100 font-14">
                                                    <!----><span class="p-tag-value">{{data.status}}</span>
                                                </span>
                                                <span class="p-tag p-component p-tag-warning p-tag-rounded mb-2 span-100 font-14 mb-2 span-100 font-14">
                                                    <!----><span class="p-tag-value">StudentID: {{data.student_id}}</span>
                                                </span>
                                                </div>
                                            
                                        </div>
                                        <div class="col-12 lg:col-10 sm:col-9 mb-3" style="float: left;">
                                            <div class="col-12 lg:col-5 sm:col-5 mb-2 " style="float: left;"><b>Name: &nbsp;</b><span>{{data.name}}</span></div>
                                            <div class="col-12 lg:col-5 sm:col-5 mb-2 " style="float: left;"><b>Email: &nbsp;</b><span>{{data.email}}</span></div>
                                            <div class="col-12 lg:col-5 sm:col-5 mb-2 " style="float: left;"><b>Mobile: &nbsp;</b><span>{{data.mobile_1}}</span></div>

                                            <div class="col-12 lg:col-5 sm:col-5 mb-2 " style="float: left;"><b>Class: &nbsp;</b><span>{{data.class}}</span></div>
                                            <div class="col-12 lg:col-5 sm:col-5 mb-2 " style="float: left;"><b>Roll No: &nbsp;</b><span>{{data.roll_no}}</span></div>

                                            <div class="col-12 lg:col-5 sm:col-5 mb-2 " style="float: left;"><b>Hostel: &nbsp;</b><span>{{data.hostel}}</span></div>
                                            <div class="col-12 lg:col-5 sm:col-5 mb-2 " style="float: left;"><b>Room No: &nbsp;</b><span>{{data.room_no}}</span></div>

                                            <div class="col-12 lg:col-5 sm:col-5 mb-2 " style="float: left;"><b>Date of Birth: &nbsp;</b><span>{{data.dob}}</span></div>
                                            <div class="col-12 lg:col-5 sm:col-5 mb-2 " style="float: left;"><b>CNIC: &nbsp;</b><span>{{data.cnic}}</span></div>
                                            <div class="col-12 lg:col-5 sm:col-5 mb-2 " style="float: left;"><b>Gender: &nbsp;</b><span>{{data.gender}}</span></div>

                                            <div class="col-12 lg:col-5 sm:col-5 mb-2 " style="float: left;"><b>Father Name: &nbsp;</b><span>{{data.father_name}}</span></div>
                                            <div class="col-12 lg:col-5 sm:col-5 mb-2 " style="float: left;"><b>Father Mobile: &nbsp;</b><span>{{data.father_mobile_no}}</span></div>
                                            <div class="col-12 lg:col-5 sm:col-5 mb-2 " style="float: left;"><b>Phone: &nbsp;</b><span>{{data.phone}}</span></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </TabPanel>

                        <TabPanel header="Room History">
                            <template #header> 
                                &nbsp; <i class="pi pi-info-circle"></i> 
                            </template> 
                            <div class="col-12 lg:col-12 sm:col-12 mb-2" > 
                                <DataTable :resizableColumns="true" columnResizeMode="fit" ref="dt" :value="roomHistory" :paginator="true" sortMode="multiple" class="p-datatable-gridlines p-datatable-sm"  :rows="10" dataKey="id" :rowHover="true" 
                                v-model:filters="filters" :loading="loading" :filters="filters" responsiveLayout="scroll"
                                :globalFilterFields="['hostel','room_no','assign_date','leave_date','status','added_by','modified_by']" :rowsPerPageOptions="[10,20,50,100]" paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown" currentPageReportTemplate="Showing {first} to {last} of {totalRecords}" v-model:selection="selectedRecords" selectionMode="multiple"
                                :scrollable="true" scrollHeight="400px">
                                
                                <template #header>
                                    <div class="flex justify-content-between flex-column sm:flex-row">  
                                        <span class="p-input-icon-left mb-2">
                                            <i class="pi pi-search" />
                                            <InputText v-model="filters['global'].value" placeholder="Keyword Search" style="width: 100%"/>
                                        </span>
                                        <MultiSelect :modelValue="selectedColumns" :options="columns" optionLabel="header" @update:modelValue="onToggle"
                                            placeholder="Select Columns" style="width: 20em"/>
                                    </div>
                                </template>
                                
                                <template #empty> 
                                    <div class="table-message"> 
                                        <img class="table-message-img" src="layout/images/no-record-found.png" alt="No record found" >
                                    </div>
                                </template>
                                <template #loading>
                                    Loading data. Please wait.
                                </template> 
                                <Column  style="max-width:3rem;flex-grow:1; flex-basis:160px"></Column>
                                <Column v-for="(col, index) of selectedColumns" :field="col.field" :header="col.header" 
                                    :key="col.field + '_' + index" 
                                    > 
                                    <template  #body="{data}">
                                        <span v-if="col.field == 'hostel'">
                                           <b>Hostel:</b> {{data.hostel}}<br />
                                           <b>Room No:</b> {{data.room_no}}
                                        </span> 
                                        <!-- <span v-if="col.field == 'room_no'">{{data.room_no}}</span>  -->
                                        <span v-if="col.field == 'assign_date'">
                                            <b>Allocation Date:</b>    {{ data.assign_date }}<br />
                                            <b>Leave Date:</b>   {{ data.leave_date ? data.leave_date : 'N/A' }}
                                        </span>
                                        <!-- <span v-if="col.field == 'leave_date'">{{ data.leave_date ? data.leave_date : 'N/A' }}</span> -->
                                        <span v-if="col.field=='added_by'">
                                            <b>Added By:</b>{{data.added_by}}<br />
                                            <b>Modified By:</b>{{data.modified_by}}<br />
                                        </span>
                                        <!-- <span v-if="col.field=='modified_by'">{{data.modified_by}}</span> -->
                                        <span v-if="col.field == 'status'"> 
                                            <Tag :class="data.status == 1 ? 'mr-2 status-Active' : 'mr-2 status-Blocked' " 
                                            :icon="data.status == 1 ? 'pi pi-check' : 'pi pi-times' " :severity="data.status == 1 ? 'success' : 'danger'" :value="data.status==1 ? 'Active' : 'Lefted'"> </Tag>
                                        </span>
                                    </template>
                                    <template #filter="{filterModel}">
                                        <span v-if="col.field == 'room_no'"> 
                                            <InputText type="text" v-model="filterModel.value" class="p-column-filter" placeholder="Search by room_no"/>
                                        </span>   
                                    </template>   
                                </Column>   
                                </DataTable>
                            </div>
                        </TabPanel>

                    </TabView>
                    <Divider />
                    <div class="col-12 lg:col-12">
                        <Toolbar class="pl-0 pr-0">
                            <template v-slot:end> 
                                <Button type="button" label="Cancel" icon="pi pi-times" class="p-right p-button-raised p-button-lg p-button-danger mr-2" @click="closeForm"/>
                            </template> 
                        </Toolbar>   
                    </div>
                </div> 
        
        </Sidebar> 
    </BlockUI>
    
</template>
<script> 
import {FilterMatchMode,FilterOperator} from 'primevue/api';  
export default {
    data() {
			return {  
                module_name:'Student Information',  
                isSaving:false,  
                saveAndClose:false, 
                recId:0,
                loadingComponent:true,
                formVisible:false, 
                data:[], 
                roomHistory:[],
                columns: null,
                filters: null,
                loading: true,
			}
		},   
        props:{
            showView : {
                type : Boolean,
                required : true,
            },
            recordId :{
                type : Number,
            },
        },
		created() {   
            this.initFilters();
            this.columns = [
                {field: 'hostel', header: 'Hostel Info' },    
                // {field: 'room_no', header: 'Room No' },    
                {field: 'assign_date', header: 'Allocation Info' },    
                // {field: 'leave_date', header: 'Leave Date' },    
                {field: 'added_by', header: 'Added/Modified By'},    
                // {field: 'modified_by', header: 'Modified By' },    
                {field: 'status', header: 'Status'}, 
            ];
            this.selectedColumns = this.columns; 
		},
        computed: { 

        },
		mounted() {  
            this.formVisible = this.showView; 
            this.recId = this.recordId;  
            this.viewRecord(); 
            this.getRoomsHistory(); 
		},
		methods: {  
             loadTargetedTab($event){
                this.showTab1 = false;
                this.showTab2 = false;
                let i = 1;
                do { 
                    if($event.index === 1){
                        this.showTab1 = true;
                    }
                    if($event.index === 2){
                        this.showTab2 = true;
                    }
                    i += 1;
                } while (i < 6);
            },  
            closeForm(){
                 this.$emit('formClose',true); 
            },
            async viewRecord(){ 
                var Vthis = this;
                await this.axios.post('getAllHosteliseStudents',{id:this.recId})
                .then( (response) => {  
                    this.data = response.data[0];  
                })
                .catch((error) => {    
                    let message = Vthis.customError(error.response); 
                    Vthis.showMessage(message,'error');  
                }).finally(() => { 
                })  
            },
            async getRoomsHistory(){ 
                if(this.recId > 0){
                    var Vthis = this;
                    this.loading = true;
                    await this.axios.post('getActiveRoom',{std_id:this.recId})
                    .then( (response) => {    
                        Vthis.roomHistory = response.data.roomHistory;
                    })
                    .catch((error) => {    
                        let message = Vthis.customError(error); 
                        Vthis.showMessage(message,'error'); 
                    })
                    .finally(() => { 
                        Vthis.loading = false;
                    })  
                }
            }, 
            initFilters() {
				this.filters = {
					'global': {value: null, matchMode: FilterMatchMode.CONTAINS},
					// 'room_no': {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.STARTS_WITH}]}, 
					// 'assign_date': {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.STARTS_WITH}]}, 
					// 'leave_date': {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.STARTS_WITH}]}, 
					// 'added_by': {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.STARTS_WITH}]}, 
					// 'modified_by': {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.STARTS_WITH}]}, 
					// 'status': {operator: FilterOperator.OR, constraints: [{value: null, matchMode: FilterMatchMode.EQUALS}]}, 
				}
			},
			clearFilter() {
				this.initFilters();
			}, 
		},
        watch : {
             
        }
}
</script>
 
