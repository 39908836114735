<template>  
    <BlockUI :blocked="blockComponent">
        <Toast />
        <ConfirmPopup></ConfirmPopup>
        <Sidebar v-model:visible="formVisible" :baseZIndex="1000" :autoZIndex="true" :dismissable="false" :showCloseIcon="false" @hide="closeForm" position="right" :class="'col-12 lg:col-8 md:col-10 sm:col-12 xs:col-12 mt-0'"> 
            <form  @keydown="form.onKeydown($event)" enctype="multipart/form-data"> 
                <div class="mt-0 mb-0">
                    <div class="col-12 lg:col-12 mb-0" v-if="data.student_id">
                        <h3 class="mb-0"> {{data.name}}</h3> 
                        <p>Student ID: {{data.student_id}} </p>
                    </div>
                    <Divider class="mt-0" />
                    <div class="grid modal-body">  
                        <div class="col-12 lg:col-6 sm:col-12 md:col-6">
                            <div>
                                <div class="grid">
                                    <div class="col-12 lg:col-6 sm:col-12 mb-2"> 
                                        <label>Room No</label>
                                        <InputText id="name" placeholder="Room No" v-model="form.room_no"  type="text" class="full-width"/>
                                        <HasError class="p-error" :form="form" field="room_no" /> 
                                    </div>
                                    <div class="col-12 lg:col-6 sm:col-12 mb-2"> 
                                        <label>Hostel</label>
                                        <Dropdown v-model="form.hostel_id" optionValue="id" :options="hostels" optionLabel="name" placeholder="Select a Hostel"  class="full-width"  />
                                        <HasError class="p-error" :form="form" field="hostel_id" /> 
                                    </div>
                                </div>
                                <div class="grid">
                                    <div class="col-12 lg:col-6 sm:col-12 mb-2"> 
                                        <label>Allocate Date</label>
                                        <Calendar id="icon" v-model="form.assign_date" :showIcon="true" class="full-width" dateFormat="yy-mm-dd"  placeholder="Allocate Date" />
                                        <HasError class="p-error" :form="form" field="assign_date" /> 
                                    </div>
                                    <div class="col-12 lg:col-6 sm:col-12 mb-2"> 
                                        <label>Leave Date</label>
                                        <Calendar id="icon" v-model="form.leave_date" :showIcon="true" class="full-width"  placeholder="Leave Date" />
                                        <HasError class="p-error" :form="form" field="leave_date" /> 
                                    </div>
                                </div>
                                <div class="grid" style="font-size:16px;">
                                    <div class="col-12 lg:col-12 sm:col-12 mb-2"> 
                                        <ul>
                                            <li>Leave date is not mendatory. leave it empty.</li>
                                            <li>When you assign new room to student the assign date will be the leave date
                                                of previous room automatically.
                                            </li>
                                            <li>
                                                if you enter record mistakely, no worry just delete that one and add new record.
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>  
                            

                        </div> 
                        <div class="col-6 lg:col-6 sm:col-6 md:col-6" v-if="roomHistory.length>0">
                            <h5>Room History</h5>
                            <div class="card-body">
                                <ul class="widget-list dashboard-employee-list " >
                                    <span v-for="(room,index) in roomHistory" :key="index">
                                    <li class="flex align-items-center py-3" :class="room.status==1 ? 'active-room':''">
                                        <div class="person-item pointer flex align-items-center">
                                           
                                            <div class="ml-2 mr-5">
                                                <div>Room No:<strong> {{room.room_no}}</strong></div>
                                            </div>
                                            <div class="ml-2 mr-5">
                                                <div class="mb-2">Assign Date:<strong> {{formatDate(room.assign_date)}}</strong></div>
                                                <div v-if="room.leave_date">Leave Date:<strong> {{formatDate(room.leave_date)}}</strong></div>
                                            </div>
                                            <div class="ml-2">
                                                <div class="mb-2">Added By:<strong> {{room.added_by}}</strong></div>
                                                <div v-if="room.modified_by">Modified By:<strong> {{room.modified_by}}</strong></div>
                                            </div>
                                        </div>
                                        <span v-if="room.status==1" class="pi pi-times quick-link-del-icon ml-auto" @click="deleteRecord(room.id);"></span>
                                       
                                    </li>
                                    </span>
                                  
                                </ul>
                             </div>
                            <!--  -->

                        </div>
                    </div>
                    <Divider />
                    <div class="col-12 lg:col-12">
                        <Toolbar class="pl-0 pr-0">
                            <template v-slot:start>
                                <Button type="submit" label="Save" :loading="isSaving" icon="pi pi-check" @click="save()" class="p-button-raised p-button-lg p-button-success mr-2"/>
                                <Button type="submit" label="Save and Close" :loading="isSaving" icon="pi pi-check" @click="saveAndClose=true, save()" class="p-button-raised p-button-lg p-button-info mr-2"/>
                                <!-- <Button type="button" label="Reset" icon="pi pi-refresh" class="p-right p-button-raised p-button-lg p-button-warning mr-2" @click="resetForm()"/> -->
                            </template>
                            <template v-slot:end> 
                                <Button type="button" label="Cancel" icon="pi pi-times" class="p-right p-button-raised p-button-lg p-button-danger mr-2" @click="closeForm"/>
                            </template> 
                        </Toolbar>   
                    </div>
                </div> 
            </form>
        </Sidebar> 
    </BlockUI>
    <ConfirmDialog group="dialog" />
</template>
<style scoped>
    .active-room{
        background-color: aquamarine !important;
    }
</style>
<script> 
import Dropdown from 'primevue/dropdown';
export default {
    data() {
			return {  
                form: new this.Form({ 
                    std_id: '', 
                    room_no: '', 
                    assign_date:this.moment(new Date()).format('YYYY-M-D'),  
                    leave_date:'',
                    status:1,  
                    hostel_id:'',
                }),   
              
                module_name:'Student Room',  
                isSaving:false,  
                saveAndClose:false, 
                recId:0,
                loadingComponent:true,
                formVisible:false, 
                data:[],
                
                roomHistory:[],
                hostels:[],
             
			}
		},   
        props:{
            showForm : {
                type : Boolean,
                required : true,
            },
            recordId :{
                type : Number,
            },
        },
		created() {  
           
		},
        computed: { 
        },
		mounted() {  
            this.resetForm();
            this.formVisible = this.showForm; 
            this.recId = this.recordId;   
            this.getAllRequestData();  
		},
		methods: {   
            getAllRequestData(){   
                this.$axios.all([this.updateRecord()])   
                .then(() => {  
                    this.viewRecord();   
                }) 
                .then(() => { 
                    this.blockComponent = false;     
                }).catch(() => {    
                //    this.getAllRequestData();
                })
                .finally(() => {  
                }); 
            },
           
            resetForm(){
                this.form.reset();
            },
            async save(){   
                var Vthis = this; 
                this.isSaving = true;
                this.form.std_id = this.recId;
                await this.form.post(Vthis.$baseurl+'api/save')
                .then(() => { 
                    if(this.saveAndClose){   
                        this.saveAndClose = false;
                        this.closeForm();
                    }   
                    Vthis.showMessage('Saved successfully','success',); 
                })
                .catch((error) => {   
                    let message = Vthis.customError(Vthis.form.errors.has('error') ? Vthis.form.errors.get('error') : error  ); 
                    Vthis.showMessage(message,'error'); 
                })
                .finally(() => {
                    this.isSaving = false; 
                    this.getAllRequestData();
                }) 
            }, 
            loadForm(){
                this.resetForm(); 
            },
            closeForm(){
                this.$emit('formClose',true); 
            },
             
            async updateRecord(){ 
                if(this.recId > 0){
                    var Vthis = this;
                    this.loading = true;
                    await this.axios.post('getActiveRoom',{std_id:this.recId})
                    .then( (response) => {    
                        //Vthis.form.fill(response.data.activeRoom[0]);
                        Vthis.roomHistory = response.data.roomHistory;
                        Vthis.hostels = response.data.hostels;
                    })
                    .catch((error) => {    
                        let message = Vthis.customError(error); 
                        Vthis.showMessage(message,'error'); 
                    })
                    .finally(() => { 
                        Vthis.loading = false;
                    })  
                }
            },  
             async viewRecord(){ 
                var Vthis = this;
                await this.axios.post('getAllHosteliseStudents',{id:this.recId})
                .then( (response) => {  
                    this.data = response.data[0];  
                })
                .catch((error) => {    
                    let message = Vthis.customError(error.response); 
                    Vthis.showMessage(message,'error');  
                }).finally(() => { 
                })  
            }, 
            deleteRecord(roomId){ 
                var Vthis = this;
                this.$confirm.require({
                    group: 'dialog',
                    header: 'Confirmation',
                    message: 'Are you sure you want to delete?',
                    icon: 'pi pi-exclamation-triangle',
                    accept: () => { 
                        Vthis.loading = true;  
                        Vthis.axios.post('deleteRoom',{id:roomId})
                        .then( () => {   
                            Vthis.getAllRequestData();
                            Vthis.showMessage('Record deleted successfully','success'); 
                        })
                        .catch((error) => {   
                            let message = Vthis.customError(error.response ); 
                            Vthis.showMessage(message,'error'); 
                        })
                        .finally(() => { 
                            Vthis.loading = false;
                        })
                    }, reject: () => {
                        // this.loading = false;
                    }
                }); 
            },
            
		},
        watch : {
             
        }
}
</script>
 
